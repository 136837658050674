import React from "react";
import { Helmet } from "react-helmet";
import { Box, Text, Link } from "@chakra-ui/react";

import NavBar from "../components/nav";
import Footer from "../components/footer";

function Info() {
	return (
		<main>
			<Helmet>
				<title>Info</title>
			</Helmet>
			<NavBar />
			<Box mt="12" px="12" textAlign="left" w="75%">
				<Text fontFamily="PT Serif" fontWeight="semibold" fontSize="3xl">
					Info
				</Text>
				<br />
				<Text fontFamily="PT Serif" fontSize="xl">
					Welcome,
					<br />
					<br />
					Currently, I am an operations director at a policy think-do tank, the
					Health in Justice (HIJ) Action Lab, within Northeastern University's
					School of Law.
					<br />
					<br />
					I have 5 years of experience in project management, with 2 years of
					experience in development, organizational leadership, and program
					management. 
					
					<br />
					<br />
					I am skilled in interdisciplinary collaboration, community
					technical assistance, reform advocacy, community outreach, policy
					analysis and proposal drafting. I've led 180% growth of a non-profit
					start-up, created leadership positions for young professionals,
					provided internal structure to a growing think-tank, and supported
					communities' success in fighting for justice reform.
				</Text>
				<Text mt="6" fontFamily="PT Serif" fontSize="xl">
					Lets Chat - aamcbride20@gmail.com
				</Text>
				<Link mt="6" fontFamily="PT Serif" fontSize="xl" color="blue" href="https://twitter.com/allisonamcbride">
					Twitter
				</Link>
				<br />
				<Link fontFamily="PT Serif" fontSize="xl" color="blue" href="https://www.linkedin.com/in/allison-mcbride-mph-a41b39155/">
					Linkedin
				</Link>
			</Box>
			<Footer />
		</main>
	);
}

export default Info;
